#social_box {
    width: 100%;
    display: flex;
}

@media (min-width: 320px) and (max-width: 480px) {

    #social_box{
        flex-direction: column;
    }

    #box1{
        order: 1;
    }

    #box2{
        order: 2;
    }

}

#icon {
    max-width: 32px;
    max-height: 32px;
}