html,
body {
    margin: 0 auto !important;
    padding: 0 !important;
    height: 100% !important;
    width: 100% !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 24px;
    color:#8094ae;
    font-weight: 400;
}
* {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
}
table,
td {
    mso-table-lspace: 0pt !important;
    mso-table-rspace: 0pt !important;
}
table {
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    table-layout: fixed !important;
    margin: 0 auto !important;
}
table table table {
    table-layout: auto;
}
a {
    text-decoration: none;
}
img {
    -ms-interpolation-mode:bicubic;
}