.App {
  text-align: left;
}

.firebaseui-container {
  margin: 0 !important;
}

.firebaseui-idp-button {
  max-width: 100% !important;
} 

.firebaseui-card-content, .firebaseui-card-footer {
  padding: 0 24px 0 10px !important;
}

.firebaseui-idp-password, .firebaseui-idp-password:hover, .mdl-button.firebaseui-idp-password:active, .mdl-button.firebaseui-idp-password:focus {
  background-color: #5b6ac6 !important;
}

.loan-btn {
  margin-left: 5px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.custom-control {
  display: inline-flex;
  min-height: 1.5rem;
  width: 100% !important;
  padding-left: 0px !important;
}

.form-control-check {
  width: 50px !important;
  margin-top: 5px;
}
